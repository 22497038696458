<template>
  <div>
    <div>
      <b-card
        action-collapse
        collapsed
      >
        <b-row class="d-flex justify-content-between align-items-center ml-1 mr-2">
          <b-card-header>
            <h4>All Packages</h4>
          </b-card-header>
          <!-- <div>
            <JsonExcel
              class="btn p-0"
              :data="rows"
              type="csv"
              :name="`Subscriptions - Page ${page}.xls`"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="Download"
                  size="15"
                />
              </b-button>
            </JsonExcel>
            <JsonExcel
              class="btn p-0"
              :fetch="fetchSubscriptionListNoPagination"
              :before-generate="__showJsonDownloadAlert"
              :fields="json_fields"
              type="csv"
              name="Subscriptions.xls"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - All'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="DownloadMultiple"
                  size="15"
                />
              </b-button>
            </JsonExcel>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary="'Refresh Table'"
              variant="outline-primary"
              size="sm"
              @click="onClickRefresh()"
            >
              <feather-icon
                icon="RefreshCwIcon"
              />
            </b-button>
          </div> -->
        </b-row>
      </b-card>
    </div>
    <div>
      <b-card
        action-collapse
        collapsed
      >
        <b-row class="auth-inner m-0 justify-content-center">
          <div class="auth-wrapper auth-v2">
            <b-row class="mt-5">
              <b-col
                v-for="(plan, index) in plans"
                :key="plan.id"
                cols="10"
                md="3"
                class="mb-4"
                style="margin-top: -10px; margin-left: 55px;"
              >
                <b-card
                  :bg-color="plan.bgColor"
                  style="border: 2px solid; width: 300px; height: 400px;"
                >
                  <b-card-title
                    class="text-center"
                    style="font-size: 24px;"
                  >
                    {{ plan.name }}
                  </b-card-title>
                  <b-card-text
                    class="d-flex justify-content-center flex-column"
                    style="height: 300px;"
                  >
                    <!-- eslint-disable vue/no-v-html-->
                    <div
                      class="text-center custom-margin"
                      style="font-size: 18px; font-weight: bold;"
                      v-html="plan.description"
                    />
                    <b-button
                      v-if="plan.upgradable"
                      variant="primary"
                      class="mt-3"
                      :class="{ 'btn-success': plan.upgradeButton === 'SUBSCRIBED' }"
                      @click="upgradePackage(plan.id, plan.tenant_id)"
                    >
                      {{ plan.upgradeButton }}
                    </b-button>
                    <!-- <b-button
                      v-else-if="plan.subscribedButton"
                      variant="success"
                      class="mt-auto"
                    >
                      {{ plan.subscribedButton }}
                    </b-button> -->
                    <!-- <b-button
                      v-else
                      variant="outline-primary"
                      class="mt-auto"
                    >
                      {{ plan.contactButton }}
                    </b-button> -->
                  </b-card-text>
                </b-card>
                <b-col
                  v-if="(index + 1) % 3 !== 0"
                  cols="10"
                  md="3"
                />
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BCardText, BButton, BCardTitle, BCard,
} from 'bootstrap-vue'
import { required } from '@validations'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const PackageRepository = RepositoryFactory.get('package')
const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  components: {
    // validations
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardTitle,
    BCard,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      showNotification: true,
      status: '',
      otpcode: '',
      // validation
      required,
      plans: [],
    }
  },
  mounted() {
    this.getPackagesDetails()
    this.activeSubscription()
  },
  methods: {
    hideNotification() {
      this.showNotification = false
    },
    navigateToSubscription() {
      this.$router.push('/subscription')
    },
    navigateToBillingPage() {
      this.$router.push('/admin-portal')
    },
    async getPackagesDetails() {
      try {
        const { data } = await PackageRepository.packageDetailsNoPagination()
        this.plans = data.data.map(plan => ({
          id: plan.id,
          name: plan.package_name,
          description: plan.package_description,
          features: [],
          upgradable: true,
          upgradeButton: 'Subscribe',
          subscribedButton: 'Subscribe',
          subscribe: true,
        }))

        const activePackges = this.plans.findIndex(plan => plan.id === this.activeSubs)
        if (activePackges !== -1) {
          this.plans[activePackges].upgradeButton = 'SUBSCRIBED'
          this.plans[activePackges].variant = 'success'
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async upgradePackage(packageId) {
      try {
        const payload = {
          package_id: packageId,
        }

        const response = await PackageRepository.packageSubscribe(payload)
        if (response.status === 200 || response.status === 201) {
          this.showSuccessMessage('Subscribed Successfully!')
          this.$router.go()
          // Update the UI after successful upgrade
          const packageIndex = this.plans.findIndex(plan => plan.id === packageId)
          if (packageIndex !== -1) {
            this.plans[packageIndex].upgradeButton = 'SUBSCRIBED'
            this.plans[packageIndex].variant = 'success'
          }
        } else {
          this.showErrorMessage(response.data.errors)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async activeSubscription() {
      try {
        this.loading = true
        const { data } = (await subscriptionRepository.getActiveSubscription())
        this.activeSubs = data.package_id
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.showErrorMessage(e.response.data.message)
        } else {
          this.showErrorMessage('Active Subscriptions Not loading!.')
        }
      }
      this.loading = false
    },
  },
}
/* eslint-disable global-require */
</script>
<style>
.search-btn {
  height: 30px;
  width: 30px;
  margin-left: 3px;
  cursor: pointer;
}
.home-btn {
  height: 30px;
  width: 30px;
  margin-left: 3px;
  cursor: pointer;
}
.notification {
  background-color: rgb(29, 164, 29);
  color: white;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}
</style>
